<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>合伙人列表</p>
        <el-button type="primary" style="margin-left: auto;" @click="agentLevelJump">等级管理</el-button>
        <el-button type="primary" @click="dialogFormVisible = true">添加合伙人</el-button>
      </div>
      <!-- <div class="query">
        <div class="queryLeft">
          <div>合伙人账号：
            <el-input class="input" v-model="serchList.mechineName" size="medium" placeholder="请输入编号"></el-input>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="agentList(serchList,1)">查询</el-button>
        </div>
      </div> -->
<!--      <div class="query">
        <div class="queryLeft">
          <div>
            <el-select class="select" size="medium" v-model="searchLevel" placeholder="请选择等级">
              <el-option v-for="item in levelListDate" :key="item.id" :label="item.levelName" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="agentListSearch()">查询</el-button>
        </div>
      </div> -->

      <el-table :data="agentData" v-loading="loading">
        <el-table-column prop="id" label="合伙人ID" align="center" width="auto"></el-table-column>
        <el-table-column prop="p_name" label="合伙人名称" align="center"> </el-table-column>
        <!-- <el-table-column prop="RegisterMobile" label="联系电话" align="center"></el-table-column> -->
        <!-- <el-table-column prop="GuaName" label="APP昵称" align="center"></el-table-column> -->
        <el-table-column prop="p_name" label="所在默认城市" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.pname}}{{scope.row.cname}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center" width="auto"></el-table-column>
<!--        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="levelChange(scope.row)">添加城市</el-button>
            <el-button size="mini" style="margin: auto;" type="primary" plain @click="partnerChange(scope.row)">更改所属
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
          background layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加合伙人" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="agentAdd">
        <el-form-item label="请输入合伙人名称:">
          <el-input v-model="agentAdd.name" style="width: 260px; float: left" placeholder="请输入合伙人名称" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入合伙人账号:">
          <el-input v-model="agentAdd.agent" style="width: 260px; float: left" placeholder="请输入合伙人账号" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请选择合伙人等级:">
          <el-select style="float: left;" v-model="agentAdd.level" filterable clearable placeholder="请选择">
            <el-option v-for="item in levelListDate" :key="item.id" :label="item.l_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省市:">
          <el-select v-model="agentAdd.province" placeholder="请选择" @change="city" style="margin-left: -63px;">
            <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="agentAdd.city" placeholder="请选择" @change="area">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
<!--          <el-select v-model="agentAdd.area" placeholder="请选择">
            <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select> -->
        </el-form-item>

        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="Adds">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="添加城市" :visible.sync="dialogFormVisibleLevel" top="50px" :close-on-click-modal="false">
      <el-form :model="agentAdd" ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请选择合伙人等级:">
          <el-select v-model="levelobj.id" filterable placeholder="请选择">
            <el-option v-for="item in levelListDate" :key="item.id" :label="item.levelName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisibleLevel = false">取消</el-button>
          <el-button type="primary" @click="Edits">修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    partnerListAll, //合伙人列表接口
    partnerAdd, //添加合伙人接口
    agentAdvShare, //设置合伙人广告分成接口
    partnerLevel, //等级等级 列表
    agentLevelLookchange, //更改合伙人等级
    zhtpartnerList, //修改合伙人时候查询所有合伙人
    agentBelong, //修改合伙人归属
    ssq, //省市区
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        agentData: [], // 合伙人列表数据
        dialogFormVisible: false, // 添加合伙人弹窗
        dialogFormVisibleLevel: false, // 修改合伙人等级弹窗
        dialogFormVisiblePartner: false, // 修改合伙人所属弹窗
        loading: false, //加载框
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        serchList: {}, //搜索栏查询
        agentAdd: {
          agent: '',
          name: '',
          level: '',
          province: '',
          city: '',
          area: '',
        }, // 添加新的合伙人
        levelListDate: [], // 等级列表
        partnerListDate: [], // 所属列表
        levelobj: {
          id: 0,
          agentid: 0
        }, //修改合伙人的等级
        partnerobj: {
          id: 0,
          agentid: 0
        }, //修改合伙人的等级
        searchLevel: '', //查询等级
        searchLevelOk: '', //查询等级确认
        provinceList: [], //省
        cityList: [], //市
        areaList: [], //区
      }
    },
    mounted() {
      this.agentList(1) // 获取合伙人列表
      this.agentLevelList() // 获取合伙人等级
      //this.zhtpartnerList() // 获取所属列表
      this.province() // 总后台省市区学校接口
    },
    methods: {
      reset() { //重置按钮
        this.searchLevel = '',
          this.searchLevelOk = '',
          this.agentList(this.currpage)
      },
      agentList(currpage) { // 合伙人列表
        this.loading = true
        const data = {}
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["lid"] = this.searchLevelOk // 查询等级

        partnerListAll(data).then(res => {
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.agentData = res.data.list // 合伙人列表
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      agentListSearch() { // 查询时候走这个方法
        this.searchLevelOk = this.searchLevel
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["lid"] = this.searchLevelOk // 每页条数

        agentList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.agentData = res.data.List // 合伙人列表
          this.currpage = 1 //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      Adds() { // 点击添加合伙人按钮
        if (!this.agentAdd.agent || !this.agentAdd.name || !this.agentAdd.level|| !this.agentAdd.province|| !this.agentAdd.city) {
          this.$message.error('请补全信息')
        } else {
          const data = {
            account: this.agentAdd.agent,
            partner_name: this.agentAdd.name,
            partner_level: this.agentAdd.level,
            province: this.agentAdd.province,
            city: this.agentAdd.city
          }
          partnerAdd(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.dialogFormVisible = false //关闭添加合伙人弹窗
              this.agentList(this.currpage) // 刷新
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      setScale(id) { //设置合伙人分成比例
        this.$prompt('请输入提成比例：', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^([0-9]{1,2}|100)$/,
          inputErrorMessage: '提成比例在0-100之间'
        }).then(({
          value
        }) => {
          let data = {
            percentage: value, //提成比例
            agentId: id, //合伙人id
          }
          agentAdvShare(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                type: 'success',
                message: '提成比例修改成功！'
              });
              this.agentList(1) // 刷新合伙人列表
            } else {
              this.$message({
                type: 'error',
                message: res.status.msg
              });
            }
          }).catch(err => console.log(err))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改！'
          });
        });
      },
      handleCurrentChange(val) { // 分页
        this.agentList(val)
      },
      agentLevelJump() {
        this.$router.push({ //页面跳转
          name: 'partnerLevelList', //往这个页面跳转
        })
      },
      levelList(serchJson, currpage) { // 合伙人列表
        this.loading = true
        const data = serchJson
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        agentList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.agentData = res.data.List // 合伙人列表
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      agentLevelList() { //查询等级列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 999 // 每页条数
        partnerLevel(data).then(res => {
          this.levelListDate = res.data.list // 等级列表
        }).catch(err => {
          console.log(err)
        })
      },
      zhtpartnerList() { //查询所属列表
        zhtpartnerList().then(res => {
          this.partnerListDate = res.data // 查询所属列表
        }).catch(err => {
          console.log(err)
        })
      },
      levelChange(obj) {
        this.levelobj.id = obj.lid
        this.levelobj.agentid = obj.agentId
        this.dialogFormVisibleLevel = true
      },
      Edits() { // 更换合伙人等级
        const data = {
          lid: this.levelobj.id,
          id: this.levelobj.agentid
        }
        agentLevelLookchange(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.dialogFormVisibleLevel = false //关闭添加合伙人弹窗
            this.agentList(this.currpage) // 刷新
          }
        }).catch(err => {
          console.log(err)
        })
      },
      partnerChange(obj) {
        this.partnerobj.id = obj.pid
        this.partnerobj.agentid = obj.agentId
        this.dialogFormVisiblePartner = true
      },
      EditsPartner() { // 更换合伙人等级
        const data = {
          partner_id: this.partnerobj.id,
          agentId: this.partnerobj.agentid
        }
        agentBelong(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.dialogFormVisiblePartner = false //关闭添加合伙人弹窗
            this.agentList(this.currpage) // 刷新
          }
        }).catch(err => {
          console.log(err)
        })
      },
      province() { // 总后台省接口
        ssq().then(res => {
          this.provinceList = res.data.province
        }).catch(err => {
          console.log(err)
        })
      },
      city() { // 总后台市接口
        this.agentAdd.city = '';
        this.agentAdd.area = '';
        const data = {
          pid: this.agentAdd.province
        }
        ssq(data).then(res => {
          this.cityList = res.data.city
        }).catch(err => {
          console.log(err)
        })
      },
      area() { // 总后台区接口
        this.agentAdd.area = '';
        const data = {
          cid: this.agentAdd.city
        }
        ssq(data).then(res => {
          this.areaList = res.data.area
        }).catch(err => {
          console.log(err)
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;

    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
      color: #000000;
      margin-left: 32px;
    }

    .btn {
      display: flex;
      align-items: center;
      width: 125px;
      height: 32px;
      opacity: 1;
      border-radius: 2px;
    }
  }

  .query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
      width: 80%;
      display: flex;

      div {
        width: 22%;
        text-align: left;

        .input,
        .select {
          width: 60%;
        }
      }
    }
  }

  .iconClick {
    color: #409EFF;
    margin-left: 3px;
    cursor: pointer;
  }
</style>
